//*// HEADER //*//

#portal-header {
    margin: 0 auto 0px;
    padding-top:15px;
    margin-bottom: 10px;
    #portal-logo {
        float: left;
        animation: slideInLeft 1s;
    }
    #portal-languageselector {
        float: left;
        clear: both;
        display: inline-block;
        margin-right: 29px;
        padding-top: 5px;
        li {
            display: inline-block;
            margin-right: 10px;
        }
    }

    #portal-anontools {
        float: right;
        ul {
            padding-right: 0px;
        }
    }
    //mobile search
    #portal-searchbox {
        padding-top: 20px;
        clear:left;
        width: 67%;
        position: absolute;
        z-index: 2;
        margin-left:3px;
        margin-top: 115px;
        white-space: nowrap;

        .searchSection {display: none;} //non on mobile
        [type="text"] {
            width: 100%;
            display: inline-block;}

        [type="submit"] {
            display: none; //submit hidden on mobile
        }
        label {font-size: 86.667%}

        //white space between elements inline-block
        form > div {font-size: 0;
            > * {font-size: @plone-font-size-base;}
        }
    }

    //non mobile search
    @media (min-width: @plone-grid-float-breakpoint) {
        #portal-languageselector {
            float: right;
            clear: none;
        }
    
        #portal-anontools {
            float: right;
        }

        #portal-searchbox {
            position: relative;
            margin-top: 5px; //visual balance
            margin-bottom: -5px; //visual balance
            margin-left: 1em;
            padding-top: 0;
            clear: none;
            float:right;
            max-width: 50%;
            width: auto;
            white-space: nowrap;
            .searchSection {display: none;} //yes for non mobile
            [type="text"] {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                width: calc(250px - 40px); //searchboxwidth - submit
                display: inline-block;
                border: none;
                box-shadow: none;
                background: @color-lightgrey;
                .border-right-radius(0);
                .border-left-radius(2px);
            }
            [type="submit"] {
                display: none; //submit shown on mobile
                width: 40px;
                display: inline-block;
                margin-left: -1px;
                vertical-align: top;
                border: none;
                box-shadow: none;
                background: @color-darkgrey;
                height: 34px;
                .border-left-radius(0);
                font-family: 'Glyphicons Halflings';
                font-style: normal;
                font-weight: normal;
                color: #fff;
                font-size: @plone-font-size-large;
                line-height: 1em;
            }
            label {font-size: 86.667%}

            //white space between elements inline-block
            form > div {font-size: 0;
                > * {font-size: @plone-font-size-base;}
            }
        }
    }

    .clearfix(); //clear floats
}
