//*// FOOTER //*//

#portal-footer-wrapper {
  padding: 0 15px;
  background-color: @plone-footer-bg;
  color: #eee;
  text-align: left;
  clear: both;

  #portal-footer > div > div {
    display: flex;

    @media (max-width: @plone-grid-float-breakpoint) {display: block;}
  }
  a {
    color: #eee;
    border-bottom : 1px dashed darken(#eee, 60%);
    &:hover {
      color: lighten(@color-lightblue,5%);
      text-decoration: none;
      border-bottom : 1px solid transparent;
    }
  }
  .portletWrapper {
      // display: inline-block;
      // width: 33%;
      flex-grow: 1;
      flex-direction: column;
      padding-bottom: 1em;
      margin-bottom: .5em;
      margin-top: 1em;

    &:first-child {
    }
    &:nth-child(2) {
    }
    &:nth-child(3) {
    }
  }
  h3 {
    color: @color-lightblue;
    font-weight: 100;
    > a {color: @color-lightblue; }

  }

  .portlet-static {

    > ul {
      width: 50%;
      float: left;
      padding-left: 0;
      list-style: none;

      &:nth-of-type(2) {
        float: right;
      }
    }

  }
  .portlet {
    background: transparent;
    border: 0;
    font-weight: @plone-font-weight-light;
    box-shadow: none;
    margin-bottom: 0;
    .portletContent {
      padding: 0;
      background: transparent;
      border: 0;
      > * {padding: inherit;}
      li {
        background: transparent;
        display: inline-block;
        border: 0;
        white-space: nowrap;
        padding: 10px 30px;
      }
    }
    &#portal-colophon .portletContent a { //remove if remove Powered by Plone
        padding: 10px 30px;
    }
    .portletItem:before, .portletItem a:before {
      content: "";
    }
    .portletItem a {
      padding: 0; border:0;
    }
    .portletActions {
      text-transform: uppercase;
      font-size: @plone-font-size-small;
      font-weight: @plone-font-weight-bold;
      margin-right: 15px;
    }
  }
}

#portal-siteactions {
  display: inline-block;
  text-transform: uppercase;
  font-size: @plone-font-size-small;
}
#portal-siteactions li {
  font-weight: bold;
  display: inline-block;
  margin-left: 30px;
  &:first-child {
    margin-left: 0;
  }
}
