/* our custom styles */


// CCAS Colors

@color-darkgrey :       #444444;
@color-grey :           #878786;
@color-lightgrey :      #e6e4e4;

@color-darkblue:        #1a5dad;
@color-lightblue:       #00adef;
@color-yellow:          #fff200;
@color-orange:          #ef7c00;
@color-darkgreen:       #00963f;
@color-lightgreen:      #6ab42b;
@color-magenta:         #be4f96;

// END CCAS Colors

.ccas-picto(@content: "") {
    content: @content;
    font-family: 'Glyphicons Halflings';
    font-style: normal;
    font-weight: normal;
    padding-right: 0.5em;
}

&.ccas-localisation::before { .ccas-picto("")}
&.ccas-phone::before { .ccas-picto("")}

.image-responsive,
.image-left,
.image-right {
    display: block;
    max-width: 100%;
    height: auto;
}
.image-left {float: left;}
.image-right {float: right;}
.image-circled {
    border-radius: 50%;
    padding: 1em;
}


.template-marche_public_container_view {
    
    #marches_publics td:last-child {
        vertical-align: middle;
    }
    a.file-download {
        background: url(../static/down-arrow.svg) no-repeat;
        display: inline-block;
        height: 30px;
        width: 30px;
    }
}
// DataTables
.dataTables_wrapper {

    .dataTables_length select {
        width: auto;
        display: inline-block;
    }
    .sorting_1 {
        font-weight: bold;
    }
    td:nth-child(8) {
       text-align: center; 
    }
    .dataTables_info {
        font-style: italic;
    }
    label {
        font-weight: normal;
    }
}

#lead-image {
    height: 200px;
    margin: -20px 0 20px 0;

    h1 {
        .triangle-button(#fff, @color-grey, auto);
        margin: 150px 0 0 0;
        padding-right: 20px;
        display: inline-block;
    }
}

body.template-newsitem_view.portaltype-news-item {
    
    footer nav.pagination { display: none};

    #sidebar .pagination ul {
        margin: 0;
        list-style: none;
        padding: 0;

        li a {
            display: block;
            // visibility: hidden;
            height: 50px;
            color: transparent;
            text-align: left;
            font-size: 24px;
            padding-left: 90px;
            line-height: 2em;
        }
        li.next a {
            background: @color-magenta url(../static/double-arrow.svg) 190px no-repeat;

            &:before {
                content:"Suivant";
                color: #fff;
            }
        }
        li.previous a {
            background: darken(@color-magenta, 10%) url(../static/double-reverse-arrow.svg) 15px no-repeat;

            &:before {
                content:"Précédent";
                color: #fff;
            }
        }
        // @media (max-width: @plone-screen-md-max) {
        //     li.next a:before, li.previous a:before { content: none;}
        // }
    }
}

// Frontpage

// hide breadcrumb content on front-page
.section-front-page #portal-breadcrumbs > div {
    visibility: hidden;
}

@media (max-width: @plone-screen-xs-max) {
    #portal-breadcrumbs {display: none;}
}

@keyframes y-slider {
    0% {background-position-y: center;}
    50% {background-position-y: 75%;}
    100% {background-position-y: center;}
}
#alert-container {
    height: 200px;
    margin-top: -30px;
    animation: y-slider 60s linear 1s infinite running;;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;

    h3 { 
        .triangle-button(#fff, @color-grey, auto);
        margin: 150px 0 0 0;
        padding-right: 20px;
        display: inline-block;
    }
    p {
        position: absolute;
        bottom: 50px;
        background: @color-darkgrey;
        .opacity(0); 
        margin: 0;
        color: #fff;
        padding: 10px;
    }
}

@keyframes slide {
    0% {background-position-x: 0;}
    50% { background-position-x: 10px;}
    100% {background-position-x: 0;}
}

.triangle-button(@background; @color: #fff; @width: 100%;) {
  color: @color;
  background: @background url(../static/arrow.svg) no-repeat;
  width: @width;
  height: 50px;
  padding: 10px 0 0 60px;

  &:hover {
    animation: slide 0.4s 0.3s;
  }

}

@keyframes blue-hover {
    100% {
        color: white;
        background: @color-lightblue;
    }
}

.more-button(@background: #fff; @color: @color-darkgrey;) {
  color: @color;
  background: @background;
  padding: 5px 10px;
  font-style: italic;
  float: right;

  &:hover {
    text-decoration: none;
    animation: blue-hover 0.3s;
    animation-fill-mode: forwards;
  }

}

.opacity (@opacity: 0.7) {
    -webkit-opacity:    @opacity;
    -moz-opacity:       @opacity;
    opacity:            @opacity;
}

#sections-container {

    .section  {
        margin-top: 30px;
        
        .caption { 
            position: absolute;
            bottom: 30px;
            width: 360px;
        }
        a:hover { text-decoration: none;}
        h3 {
            margin: 0;        
            position: relative;
        }
        p {
            position: relative;
            background: @color-darkgrey;
            .opacity(0); 
            margin: 0;
            padding: 10px;
            animation-duration: 0.5s;

            span {
                color: white;
                font-size: 16px;
            }
        }
        & > img {
            border-radius: 5%;   
        }
    }
    & > div:nth-child(2n-1) .section > img {
        border-radius: 50%;
    }
    & > div:nth-child(1) .section {
        .caption {animation-delay: 0.1s;}
        h3 { .triangle-button(@color-lightblue);}
    }
    & > div:nth-child(2) .section {
        .caption {animation-delay: 0.2s;}        
        h3 { .triangle-button(@color-orange);}
    }
    & > div:nth-child(3) .section {
        .caption {animation-delay: 0.3s;}
        h3 { .triangle-button(@color-darkgreen);}
    }
    & > div:nth-child(4) .section {
        .caption {animation-delay: 0.6s;}
        h3 { .triangle-button(@color-grey);}
    }
    & > div:nth-child(5) .section {
        .caption {animation-delay: 0.5s;}
        h3 { .triangle-button(@color-darkblue);}
    }
    & > div:nth-child(6) .section {
        .caption {animation-delay: 0.4s;}
        h3 { .triangle-button(white, @color-grey);}
    }

}

.news-wrapper {
    background: @color-lightgrey url(../static/big-ccas-arrow.svg) no-repeat;
    margin-top: 30px;
}

#news-container {
    padding-bottom: 30px;

    a, a:hover {text-decoration: none; border: none;}
    h2 {
        .triangle-button(white, @color-darkgrey);
        margin: 0 0 20px 0;
    }
}

#front-timeline {

    margin-top: 30px;

    ul.timeline {
        border-left: 5px #fff solid;
        margin-left: 100px;
        padding-left: 20px;
        list-style: none;
        
        time {
            display: block;
            position: relative;
            left: -125px;
        }

        i.timeline-point {
            width: 10px;
            height: 10px;
            position: absolute;
            background: @color-lightblue;
            margin: -15px 0 0 -27px;
            box-shadow: 0 0 0 4px #fff;
            -webkit-font-smoothing: antialiased;
            border-radius: 30px;
            z-index: 20;
        }

        .news {
            position: relative;
            display: block;
            margin: -45px 0 50px 0;

            h3 {color: @color-lightblue;}
            p {text-align: justify;}
            a {.more-button();}
        }
    }
    @media (max-width: @plone-grid-float-breakpoint) {
        ul.timeline {
            margin-left: 0;
            border: none;
        }
        time { 
            position: initial;
            left: 0
        }
        i.timeline-point {display: none;}
        .news {margin: 0;}
    }


}

#front-focus {

    margin-top: 30px;

    .caption  {
        position: absolute;
        top: 0;
        width: ~"calc(100% - 30px)";
        // overflow: hidden;

        a {
            text-decoration: none;
            display: block;
        }

        i.eye {
            background: transparent url(../static/eye.svg) no-repeat;
            height: 80px;
            width: 100px;
            display: block;
            position: absolute;
            top: 10px;
            left: 20px;
        }

        h3 {
            // width: ~"calc(100% - 15px)";
            padding: 5px 0 0 160px;
            margin : 0;
            background: darken(@color-magenta, 15%) url(../static/nude-arrow.svg) 120px no-repeat;
            color: white;
            font-size : 36px;
            height: 50px;
        }

        h4 {
            // width: ~"calc(100% - 15px)";
            padding: 10px 0 10px 120px;
            margin : 0;
            background: @color-magenta;
            color: white;
            min-height: 50px;
            font-size : 24px;
            line-height: 2em;
        }

        p {
            // width: ~"calc(100% - 15px)";
            position: relative;
            background: @color-darkgrey;
            .opacity(0); 
            margin: 0;
            // visibility: hidden;
            padding: 10px;
            animation-duration: 0.5s;

            span {
                color: white;
                font-size: 16px;
            }
        }

        @media (max-width: @plone-grid-float-breakpoint) {
            i.eye  {display: none;}
            h3 {
                padding: 5px  5px 5px 55px;
                background-position-x:  15px;
                line-height: 1.5em;

            }
            h4 {padding: 10px 0 10px 15px;}
        }

        @media (max-width: @plone-screen-xs-max) {
            h3 {font-size : 24px;}
            h4 {font-size : 18px;}
        }
    }
}

 .contact {

        height: 100px;
        margin-top: 20px;
        // position: absolute;

        a {
            text-decoration: none;
            display: block;
        }

        i.interrogation {
            background: transparent url(../static/interrogation.svg) no-repeat;
            height: 80px;
            width: 100px;
            display: block;
            margin: -90px 0 0 20px;
            // position: absolute;
            // top: 10px;
            // left: 20px;
        }

        h3 {
            padding: 5px 0 0 160px;
            margin : 0;
            background:  @color-lightgreen url(../static/nude-arrow.svg) 120px no-repeat;
            color: white;
            font-size : 36px;
            height: 50px;

        }

        h4 {
            padding: 10px 0 10px 120px;
            margin : 0;
            background: darken(@color-lightgreen, 10%);
            color: white;
            min-height: 50px;
            font-size : 24px;
            line-height: 2em;
        }

    @media (max-width: @plone-grid-float-breakpoint) {
        i.interrogation  {display: none;}
        h3 {
            padding: 5px  5px 5px 55px;
            background-position-x:  15px;
            line-height: 1.5em;

        }
        h4 {padding: 10px 0 10px 15px;}
    }
      
    @media (max-width: @plone-screen-xs-max) {
        h3 {font-size : 24px;}
        h4 {font-size : 18px;}
    }

}

// Portlet
.portlet-static-contact {
    
    margin-bottom: 20px;

    a {
        text-decoration: none;
        display: block;
        color: white;
    }
    a:hover, a:visited, a:active { border: none;}

    h3 {
        padding: 10px 0 10px 50px;
        margin : 0;
        background:  @color-lightgreen url(../static/nude-arrow.svg) 15px no-repeat;
        color: white;
        height: 50px;
        font-size : 24px;
    }
    h4 {
        font-size : @plone-font-size-base;
        padding: 10px 0 10px 50px;
        margin : 0;
        background: darken(@color-lightgreen, 10%) url(../static/interrogation.svg) 10px no-repeat;
        background-size: 30px;
        color: white;
        min-height: 50px;
        line-height: 2em;
    }
    // @media (max-width: @plone-screen-sm-max) {
    //     h3 {font-size : 20px;}
    //     h4 {font-size : 12px;}
    // }
}


@keyframes nav-hover {
    100% {
        background: @color-lightgrey;
        color: @color-darkgrey;
    }
}
.portlet.portletNavigationTree {

    border: none;
    background: @color-lightgrey;
    box-shadow: none;
    border-radius: 0;
    border: none;

    .portletContent > ul {
        > li > a {
            content: "⟩";
            background: #fff;
            &::after {content:"⟩"; position: absolute; right: 10px; font-size: @plone-font-size-large; margin-top:-3px; color: @plone-gray-light;}

            &:hover {
                text-decoration: none;
            }
        }

        a.navTreeCurrentNode {
            background: @color-lightgrey;
            color: @color-darkgrey;

        }

        a:hover {
            text-decoration: none;
            animation: nav-hover 0.3s;
            animation-fill-mode: forwards;

        }
    }
}

@keyframes news-hover {
    100% {
        background: #fff;
    }
}
.portlet.portletNews,
.portlet.portletCollection.portlet-collection-actualites {

    background: @color-lightgrey;
    box-shadow: none;
    border-radius: 0;
    border: none;

    header.portletHeader {
        background: @color-lightblue url(../static/arrow.svg) no-repeat;
        color: white;
        font-size : 24px;
        padding: 0 0 0 60px;
        height: 50px;
        line-height: 2em;
        border-radius: 0;
    }

    .portletContent {
        border: none;
        background: @color-lightgrey;

        > ul {
            border-left: 5px #fff solid;
            margin: 10px 0 10px 15px;
            padding-left: 10px;
            list-style: none;
        
            time {
                display: block;
                font-style: italic;
            }

            > li {

                &:hover { background: transparent;}
            
                > a {

                    color: @color-lightblue;
                    font-size: @plone-font-size-large;
                    padding-left: 10px;

                    &::before {
                        content: " ";
                        width: 10px;
                        height: 10px;
                        position: absolute;
                        margin: 0;
                        background: @color-lightblue;
                        box-shadow: 0 0 0 4px #fff;
                        -webkit-font-smoothing: antialiased;
                        border-radius: 30px;
                        z-index: 20;
                        left: -18px;
                        top: 18px;

                    }
                    &:hover {text-decoration: none;}
                    &:hover::before {
                        animation: news-hover 0.2s;
                        animation-fill-mode: forwards;
                    }



                }
                .portletItemDetails {
                    padding-left: 10px;
                }
            }
        }
    }

    footer.portletFooter {
        background: @color-lightgrey;
        text-align: right;
        box-shadow: none;

        a, a:hover, a:visited {
            color: @color-lightblue;
            font-style: italic;
            border-bottom: 2px lighten(@color-lightblue, 10%) dotted;
            text-decoration: none;
        }
    }
}

.template-summary_view {

    &.section-actualites nav.pagination { display: none;}

    article {
        margin-bottom: 50px;
        h2 a {
            color: @color-lightblue;
            text-decoration: none;
            &:hover {
                border: none;
            }
        }
    }
    .tileBody .description {
        font-style: italic;;
        text-align: justify;
        
    }
    .tileFooter a {
        .more-button();
        background: @color-lightgrey;
    }

}


// @-webkit-keyframes fadeInUp {
//   from {
//     opacity: 0;
//     -webkit-transform: translate3d(0, 100%, 0);
//     transform: translate3d(0, 100%, 0);
//   }

//   to {
//     opacity: 0.7;
//     -webkit-transform: translate3d(0, 0, 0);
//     transform: translate3d(0, 0, 0);
//   }
// }

// @keyframes fadeInUp {
//   from {
//     opacity: 0;
//     -webkit-transform: translate3d(0, 100%, 0);
//     transform: translate3d(0, 100%, 0);
//   }

//   to {
//     opacity: 0.7;
//     -webkit-transform: translate3d(0, 0, 0);
//     transform: translate3d(0, 0, 0);
//   }
// }