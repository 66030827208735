//*// FONTS //*//


//*// Standard

// 500
// @font-face {
//     font-family: "Open Sans";
//     font-style: normal;
//     font-weight: 500;
//     src: local('Open Sans Bold'), local('OpenSansBold'), url("open-sans/OpenSans-Bold.ttf") format('truetype');
// }
// @font-face {
//     font-family: "Open Sans";
//     font-style: italic;
//     font-weight: 500;
//     src: local('Open Sans Bold Italic'), local('OpenSansBoldItalic'), url("open-sans/OpenSans-BoldItalic.ttf") format('truetype');
// }
// 100
@font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 100;
    src: local('Open Sans Light'), local('OpenSansLight'), url("open-sans/OpenSans-Light.ttf") format('truetype');
}
@font-face {
    font-family: "Open Sans";
    font-style: italic;
    font-weight: 100;
    src: local('Open Sans Light Italic'), local('OpenSansLightItalic'), url("open-sans/OpenSans-LightItalic.ttf") format('truetype');
}

// 300
@font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 300;
    src: local('Open Sans'), local('OpenSans'), url("open-sans/OpenSans-Regular.ttf") format('truetype');
}
@font-face {
    font-family: "Open Sans";
    font-style: italic;
    font-weight: 300;
    src: local('Open Sans Italic'), local('OpenSansItalic'), url("open-sans/OpenSans-Italic.ttf") format('truetype');
}
